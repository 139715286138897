import dealsService from 'services/dealsService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const getDeal = createAsyncThunk('deals/getDeal', async dealId => {
  try {
    const { data } = await dealsService.getDeal(dealId);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getCompanySharedDeals = createAsyncThunk(
  'deals/getCompanySharedDeals',
  async companyId => {
    try {
      const { data } = await dealsService.getCompanySharedDeals(companyId);
      return data;
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const getCompanyRecievedDeals = createAsyncThunk(
  'deals/getCompanyRecievedDeals',
  async companyId => {
    try {
      const { data } = await dealsService.getCompanyRecievedDeals(companyId);
      return data;
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const cancelDeal = createAsyncThunk('deals/cancelDeal', async dealId => {
  try {
    const { data } = await dealsService.cancelDeal(dealId);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const createDeal = createAsyncThunk('deals/createDeal', async ({ companyId, newDeal }) => {
  try {
    const { data } = await dealsService.createDeal(companyId, newDeal);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const { fulfilled: getDealFulfilled, rejected: getDealRejected } = getDeal;
export const {
  fulfilled: getCompanySharedDealsFulfilled,
  rejected: getCompanySharedDealsRejected
} = getCompanySharedDeals;
export const {
  fulfilled: getCompanyRecievedDealsFulfilled,
  rejected: getCompanyRecievedDealsRejected
} = getCompanyRecievedDeals;
export const { fulfilled: cancelDealFulfilled, rejected: cancelDealRejected } = cancelDeal;
export const { fulfilled: createDealFulfilled, rejected: createDealRejected } = createDeal;
