import React from 'react';
import { styled, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import secondImage from '../../assets/images/secondPartLanding.jpg';
import dataJanitoring from '../../assets/dataJanitoring.svg';
import dataBenchmarking from '../../assets/dataBenchmarking.svg';
import dataVisualization from '../../assets/dataVisualization.svg';
import rapidImplementation from '../../assets/rapidImplementation.svg';

const SecondPart = styled(Box)(({ theme }) => ({
  height: '64.17vw',
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
    height: 'auto',
    width: '100%'
  }
}));

const MissionContainer = styled(Box)(({ theme }) => ({
  width: '38.75vw',
  backgroundColor: theme.palette.info.main,
  position: 'relative',
  '&::after': {
    opacity: '0.9',
    content: '""',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${secondImage})`,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    mixBlendMode: 'multiply',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center'
  },
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    paddingTop: '11.79vw',
    paddingLeft: '4.1vw',
    paddingRight: '21.8vw',
    paddingBottom: '20.51vw'
  }
}));

const MissionTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '2.5vh',
  paddingLeft: '3.47vw',
  paddingTop: '27.92vw',
  color: theme.palette.cream.main,
  position: 'relative',
  zIndex: 2,
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    paddingTop: '0',
    paddingLeft: '0',
    fontSize: '2.4rem',
    fontWeight: 400
  }
}));

const MissionText = styled(Typography)(({ theme }) => ({
  fontSize: '2.4rem',
  lineHeight: '120%',
  marginTop: '2.22vw',
  paddingLeft: '3.47vw',
  width: '31.94vw',
  color: theme.palette.cream.main,
  position: 'relative',
  zIndex: 2,
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    marginTop: '5.38vw',
    paddingLeft: '0',
    fontSize: '1.6rem',
    letterSpacing: '0.0015em'
  }
}));

const FeaturesContainer = styled(Box)(({ theme }) => ({
  width: '61.25vw',
  justifyContent: 'center',
  [theme.breakpoints.down('mobile')]: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
    paddingTop: '14.62vw',
    paddingBottom: '16.67vw',
    paddingLeft: '4.87vw',
    paddingRight: '3.08vw'
  }
}));

const FirstFeature = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '45.14vw',
  height: '9.22vw',
  marginLeft: '11.94vw',
  marginTop: '8.4vw',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    marginLeft: '0',
    marginTop: '0',
    alignItems: 'center'
  }
}));

const Feature = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '45.14vw',
  height: '9.22vw',
  marginLeft: '11.94vw',
  marginTop: '3.97vw',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    marginLeft: '0',
    alignItems: 'center'
  }
}));

const FeatureCircle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  component: 'span',
  width: '9.22vw',
  height: '9.22vw',
  borderRadius: '50%',
  marginRight: '0.56vw',
  [theme.breakpoints.down('mobile')]: {
    width: '20.36vw',
    height: '16.36vw',
    margin: '0'
  }
}));

const FeatureIcon = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '5.07vw',
  height: '5.69vw',
  [theme.breakpoints.down('mobile')]: {
    width: '9.14vw',
    height: '10.05vw'
  }
}));

const FeatureData = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '3.68vw',
  flexDirection: 'column',
  width: '32.22vw',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    marginLeft: '4.96vw'
  }
}));

const FeatureTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  width: '29.17vw',
  fontSize: '2.4rem',
  lineHeight: '2.4rem',
  fontWeight: '700',
  color: theme.palette.info.main,
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    fontSize: '1.6rem',
    fontWeight: 700
  }
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontSize: '2.4rem',
  lineHeight: '120%',
  fontWeight: '400',
  color: theme.palette.info.main,
  marginTop: '1.67vw',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    fontSize: '1.6rem',
    marginTop: '1.03vw'
  }
}));

const LandingPageSecondPart = ({ aboutUsRef }) => {
  return (
    <SecondPart ref={aboutUsRef}>
      <MissionContainer>
        <MissionTitle variant="h1">Mather’s Mission</MissionTitle>
        <MissionText>
          Personalize, simplify, and automate the down-market M&A economy, laying the foundation for
          investment Banking-as-a-Service.
        </MissionText>
      </MissionContainer>
      <FeaturesContainer>
        <FirstFeature>
          <FeatureCircle>
            <FeatureIcon src={dataJanitoring} alt="Data Janitoring" />
          </FeatureCircle>
          <FeatureData>
            <FeatureTitle>Data Janitoring</FeatureTitle>
            <FeatureDescription>
              Mather connects all key financial information and stores it in one common schema of
              information.
            </FeatureDescription>
          </FeatureData>
        </FirstFeature>
        <Feature>
          <FeatureCircle>
            <FeatureIcon src={dataBenchmarking} alt="Data Benchmarking" />
          </FeatureCircle>
          <FeatureData>
            <FeatureTitle>Data Benchmarking</FeatureTitle>
            <FeatureDescription>
              Mather includes key benchmarks by industry for your segment of the market.
            </FeatureDescription>
          </FeatureData>
        </Feature>
        <Feature>
          <FeatureCircle>
            <FeatureIcon src={dataVisualization} alt="Data Visualization" />
          </FeatureCircle>
          <FeatureData>
            <FeatureTitle>Data Visualization</FeatureTitle>
            <FeatureDescription>
              Mather’s software visualizes key data points in a way that is easy for buyers and
              sellers to understand.
            </FeatureDescription>
          </FeatureData>
        </Feature>
        <Feature>
          <FeatureCircle>
            <FeatureIcon src={rapidImplementation} alt="Rapid Implemention & Integration" />
          </FeatureCircle>
          <FeatureData>
            <FeatureTitle>Rapid Implemention & Integration</FeatureTitle>
            <FeatureDescription>
              Contracting to implementation in less than 2 days.
            </FeatureDescription>
          </FeatureData>
        </Feature>
      </FeaturesContainer>
    </SecondPart>
  );
};

LandingPageSecondPart.propTypes = {
  aboutUsRef: PropTypes.object.isRequired
};

export default LandingPageSecondPart;
