import { createAction } from '@reduxjs/toolkit';
import userService from 'services/userService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const login = createAsyncThunk('user/login', async user => {
  try {
    const { data } = await userService.login(user);
    return data;
  } catch ({ response: { data } }) {
    throw new Error(data.description);
  }
});

export const logout = createAsyncThunk('user/logout', async () => {
  try {
    await userService.logout();
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const signUp = createAsyncThunk('user/signup', async user => {
  try {
    const { data } = await userService.signUp(user);
    return data;
  } catch ({ response: { data } }) {
    throw new Error(data.description);
  }
});

export const resendConfirmationEmail = createAsyncThunk(
  'user/resendConfirmationEmail',
  async email => {
    try {
      await userService.resendConfirmationEmail(email);
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const recoverPassword = createAsyncThunk('user/recoverPassword', async email => {
  try {
    const { data } = await userService.recoverPassword(email);
    return data;
  } catch (error) {
    throw new Error(error.response.data.description);
  }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async ({ token, password }) => {
  try {
    const { data } = await userService.resetPassword(password, token);
    return data;
  } catch (error) {
    throw new Error(error.response.data.description);
  }
});

export const setQuickbooksYear = createAsyncThunk('user/quickbooksYear', async quickbooksYear => {
  return quickbooksYear;
});

export const updateSession = createAction('session/update');

export const { fulfilled: loginFulfilled, rejected: loginRejected } = login;
export const { fulfilled: signUpFulfilled } = signUp;
export const { fulfilled: logoutFulfilled } = logout;
export const {
  fulfilled: recoverPasswordFulfilled,
  rejected: recoverPasswordRejected
} = recoverPassword;
export const { fulfilled: resetPasswordFulfilled, rejected: resetPasswordRejected } = resetPassword;
export const { fulfilled: setQuickbooksYearFulfilled } = setQuickbooksYear;
