import httpClient from 'httpClient';
import { BASE_DEALS_URL } from 'constants/networkConstants';

class DealsService {
  static getDeal(dealId) {
    return httpClient.get(`${BASE_DEALS_URL}/${dealId}`);
  }

  static getCompanySharedDeals(companyId) {
    return httpClient.get(`${BASE_DEALS_URL}/${companyId}/shared`);
  }

  static getCompanyRecievedDeals(companyId) {
    return httpClient.get(`${BASE_DEALS_URL}/${companyId}/received`);
  }

  static cancelDeal(dealId) {
    return httpClient.put(`${BASE_DEALS_URL}/${dealId}/cancel`);
  }

  static createDeal(companyId, newDeal) {
    return httpClient.post(`${BASE_DEALS_URL}/${companyId}`, newDeal);
  }
}

export default DealsService;
