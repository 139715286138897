import { createSlice } from '@reduxjs/toolkit';
import { getIndicatorsFulfilled, getIndicatorFulfilled } from 'state/actions/indicatorsActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  indicators: [],
  indicator: {}
};

const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState,
  extraReducers: {
    [getIndicatorsFulfilled]: (state, { payload }) => {
      state.indicators = payload;
    },
    [getIndicatorFulfilled]: (state, { payload }) => {
      state.indicator = payload;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default indicatorsSlice.reducer;
