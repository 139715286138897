import httpClient from 'httpClient';
import { BASE_METRICS_URL } from 'constants/networkConstants';

class MetricsService {
  static getMetrics() {
    return httpClient.get(BASE_METRICS_URL);
  }
}

export default MetricsService;
