export const BASE_AUTH_URL = '/api/v1/auth';
export const BASE_COMPANIES_URL = '/api/v1/companies';
export const BASE_CONFIRMATIONS_URL = '/api/v1/confirmations';
export const BASE_USER_URL = '/api/v1/users';
export const BASE_METRICS_URL = '/api/v1/metrics';
export const BASE_INDUSTRIES_URL = '/api/v1/industries';
export const BASE_DEALS_URL = '/api/v1/deals';
export const BASE_INDICATORS_URL = '/api/v1/indicators';
export const BASE_DASHBOARD_URL = '/api/v1/dashboard';
export const BASE_QUICKBOOKS_URL = '/api/v1/quickbooks';
