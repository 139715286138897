import { createSlice } from '@reduxjs/toolkit';
import {
  getCompaniesFulfilled,
  getCompanyFulfilled,
  getIndustriesFulfilled,
  getIndustryFulfilled,
  getCompanyAccountsFulfilled,
  getCompanyUsersFulfilled,
  getUserFulfilled,
  getUserCompaniesFulfilled
} from 'state/actions/companyActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  companies: [],
  company: {},
  industries: [],
  industry: {},
  companyAccounts: [],
  companyUsers: [],
  user: {},
  userCompanies: []
};

const metricsSlice = createSlice({
  name: 'companies',
  initialState,
  extraReducers: {
    [getCompaniesFulfilled]: (state, { payload }) => {
      state.companies = payload;
    },
    [getCompanyFulfilled]: (state, { payload }) => {
      state.company = payload;
    },
    [getIndustriesFulfilled]: (state, { payload }) => {
      state.industries = payload;
    },
    [getIndustryFulfilled]: (state, { payload }) => {
      state.industry = payload;
    },
    [getCompanyAccountsFulfilled]: (state, { payload }) => {
      state.companyAccounts = payload;
    },
    [getCompanyUsersFulfilled]: (state, { payload }) => {
      state.companyUsers = payload;
    },
    [getUserFulfilled]: (state, { payload }) => {
      state.user = payload;
    },
    [getUserCompaniesFulfilled]: (state, { payload }) => {
      state.userCompanies = payload;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default metricsSlice.reducer;
