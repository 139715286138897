import React from 'react';
import { styled, Box, Divider, Typography, Link, IconButton } from '@mui/material';
import { Link as LinkDom, useLocation } from 'react-router-dom';
import routes from 'constants/routesPaths';

import InstagramLogo from '../../assets/footer/Instagram.svg';
import LinkedinLogo from '../../assets/footer/Linkedin.svg';
import TwitterLogo from '../../assets/footer/Twitter.svg';
import FacebookLogo from '../../assets/footer/Facebook.svg';
import PrivacyPolicyFile from '../../assets/footer/PrivacyPolicy.pdf';
import TermsOfUseFile from '../../assets/footer/TermsOfUse.pdf';

const FooterContainer = styled(Box)(({ theme, dynamicWidth, dynamicMargin }) => ({
  display: 'flex',
  width: dynamicWidth,
  marginLeft: dynamicMargin,
  height: 'auto',
  padding: '2.2rem 16.6rem',
  [theme.breakpoints.down('mobile')]: {
    padding: '0.8rem 2rem 0.8rem 2rem'
  }
}));

const LinksContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  height: 'auto'
});

const HorizontalDivider = styled(Divider)({
  width: '100%'
});

const GroupedLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  width: '25%',
  height: 'auto',
  padding: '1rem 0rem',
  [theme.breakpoints.down('mobile')]: {
    padding: 0,
    justifyContent: 'center'
  }
}));

const LinkTo = styled(Link)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '1rem 1.4rem',
  color: '#000000',
  fontSize: '1.2rem',
  fontFamily: 'DM Sans',
  lineHeight: '140%',
  letterSpacing: '-0.05rem',
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1rem',
    textAlign: 'center',
    padding: '0.6rem 1rem'
  }
}));

const SocialLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50%',
  height: 'auto',
  padding: '1rem 1.4rem',
  fontSize: '1.2rem',
  fontFamily: 'DM Sans',
  lineHeight: '140%',
  letterSpacing: '-0.05rem',
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1rem',
    textAlign: 'center',
    padding: '0.6rem 1rem'
  }
}));

const LinkCircle = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  component: 'span',
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '50%',
  marginRight: '0.8rem',
  [theme.breakpoints.down('mobile')]: {
    width: '1.8rem',
    height: '1.8rem'
  }
}));

const FeatureIcon = styled('img')(({ theme }) => ({
  display: 'flex',
  width: '150%',
  height: 'auto',
  [theme.breakpoints.down('mobile')]: {
    width: '500%'
  }
}));

const LinksText = styled(Typography)({
  display: 'flex',
  paddingRight: '2.5rem',
  alignItems: 'center'
});

const LastText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: 'auto',
  marginTop: '4rem',
  justifyContent: 'center',
  fontSize: '1.2rem',
  fontFamily: 'DM Sans',
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1rem',
    marginTop: '2rem'
  }
}));

const Footer = () => {
  const { pathname } = useLocation();
  const dynamicWidth = pathname === routes.dashBoard ? '84%' : '100%';
  const dynamicMargin = pathname === routes.dashBoard ? '16%' : '0';

  if (pathname.toLowerCase().includes(routes.dashBoard.toLowerCase())) return null;

  return (
    <FooterContainer dynamicWidth={dynamicWidth} dynamicMargin={dynamicMargin}>
      <LinksContainer>
        <HorizontalDivider />
        <GroupedLinks>
          <LinkDom underline="hover" to={routes.aboutUs} component={LinkTo}>
            About us
          </LinkDom>
          <LinkTo underline="hover" href={`mailto:${process.env.EMAIL_FEEDBACK}`}>
            Feedback
          </LinkTo>
        </GroupedLinks>
        <GroupedLinks>
          <LinkTo underline="hover" href={TermsOfUseFile} target="_blank">
            Terms of Service
          </LinkTo>
          <LinkTo underline="hover" href={PrivacyPolicyFile} target="_blank">
            Privacy Policy
          </LinkTo>
        </GroupedLinks>
        <GroupedLinks>
          <LinkTo underline="hover">Cookie Policy</LinkTo>
          <LinkTo underline="hover">Desktop App</LinkTo>
        </GroupedLinks>
        <GroupedLinks>
          <LinkTo underline="hover">Help & Support</LinkTo>
          <LinkTo underline="hover">Trust, Safety & Security</LinkTo>
        </GroupedLinks>
        <HorizontalDivider />
        <SocialLinks>
          <LinksText>Follow us</LinksText>
          <LinkCircle href={process.env.INSTAGRAM_URL}>
            <FeatureIcon src={InstagramLogo} alt="Instagram" />
          </LinkCircle>
          <LinkCircle href={process.env.LINKEDIN_URL}>
            <FeatureIcon src={LinkedinLogo} alt="Linkedin" />
          </LinkCircle>
          <LinkCircle href={process.env.TWITTER_URL}>
            <FeatureIcon src={TwitterLogo} alt="Twitter" />
          </LinkCircle>
          <LinkCircle href={process.env.FACEBOOK_URL}>
            <FeatureIcon src={FacebookLogo} alt="Facebook" />
          </LinkCircle>
        </SocialLinks>
        <HorizontalDivider />
        <LastText>2021 - Mather</LastText>
      </LinksContainer>
    </FooterContainer>
  );
};

export default Footer;
