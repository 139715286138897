import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useSession } from 'hooks';
import RouteFromPath from 'components/routes/RouteFromPath';
import routes from '../routes';
import Header from './common/Header';
import Footer from './common/Footer';

const App = () => {
  const { authenticated, hasCompanies } = useSession();

  return (
    <>
      <Helmet>
        <title>Mather</title>
      </Helmet>
      <BrowserRouter>
        <Header />
        <Switch>
          {routes.map((route, index) => (
            <RouteFromPath
              key={`route${index}`}
              {...route}
              authenticated={authenticated}
              hasCompanies={hasCompanies}
            />
          ))}
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
