import companyService from 'services/companyService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const getCompanies = createAsyncThunk('company/getCompanies', async () => {
  try {
    const { data } = await companyService.getCompanies();
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const createCompany = createAsyncThunk('company/create', async company => {
  try {
    const { data } = await companyService.createCompany(company);
    return data;
  } catch ({ response: { data } }) {
    let errorMessage = data.description;
    if (data.httpCode === 400) {
      errorMessage = errorMessage.includes('already exists')
        ? `${company.name} already exists.`
        : `You have errors in your data: ${data.errors.join('. ')}`;
    }
    throw new Error(errorMessage);
  }
});

export const getCompany = createAsyncThunk('company/getCompany', async company => {
  try {
    const { data } = await companyService.getCompany(company);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const postProfitAndLoss = createAsyncThunk(
  'companies/postProfitAndLoss',
  async ({ id, accounts }) => {
    try {
      const { data } = await companyService.postProfitAndLoss(id, accounts);
      return data;
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const getIndustries = createAsyncThunk('company/getIndustries', async () => {
  try {
    const { data } = await companyService.getIndustries();
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getIndustry = createAsyncThunk('company/getIndustry', async id => {
  try {
    const { data } = await companyService.getIndustry(id);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getCompanyAccounts = createAsyncThunk('companies/getCompanyAccounts', async id => {
  try {
    const { data } = await companyService.getCompanyAccounts(id);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getCompanyUsers = createAsyncThunk('companies/getCompanyUsers', async id => {
  try {
    const { data } = await companyService.getCompanyUsers(id);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getUser = createAsyncThunk('companies/getUser', async id => {
  try {
    const { data } = await companyService.getUser(id);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getUserCompanies = createAsyncThunk('companies/getUserCompanies', async () => {
  try {
    const { data } = await companyService.getUserCompanies();
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const { fulfilled: getCompaniesFulfilled, rejected: getCompaniesRejected } = getCompanies;
export const { fulfilled: createCompanyFulfilled, rejected: createCompanyRejected } = createCompany;
export const { fulfilled: getCompanyFulfilled, rejected: getCompanyRejected } = getCompany;
export const {
  fulfilled: postProfitAndLossFulfilled,
  rejected: postProfitAndLossRejected
} = postProfitAndLoss;
export const { fulfilled: getIndustriesFulfilled, rejected: getIndustriesRejected } = getIndustries;
export const { fulfilled: getIndustryFulfilled, rejected: getIndustryRejected } = getIndustry;
export const {
  fulfilled: getCompanyAccountsFulfilled,
  rejected: getCompanyAccountsRejected
} = getCompanyAccounts;
export const {
  fulfilled: getCompanyUsersFulfilled,
  rejected: getCompanyUsersRejected
} = getCompanyUsers;
export const { fulfilled: getUserFulfilled, rejected: getUserRejected } = getUser;
export const {
  fulfilled: getUserCompaniesFulfilled,
  rejected: getUserCompaniesRejected
} = getUserCompanies;
