import { useSession } from 'hooks';
import React from 'react';
import { Redirect } from 'react-router';
import routes from 'constants/routesPaths';

const NotFoundPage = () => {
  const { authenticated, hasCompanies } = useSession();

  if (authenticated && hasCompanies) return <Redirect to={routes.dashBoard} />;

  return <Redirect to={routes.index} />;
};

export default NotFoundPage;
