import React from 'react';
import { Box, styled, AppBar, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import routes from 'constants/routesPaths';

import logo from '../../assets/logoMather.svg';
import logoSolo from '../../assets/logoSolo.svg';

const HeaderContainer = styled(Box)({
  flexGrow: 1
});

const HeaderBar = styled(AppBar)(({ theme }) => ({
  boxShadow: 0,
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%'
  }
}));

const ToolBar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  [theme.breakpoints.down('mobile')]: {
    padding: '5.64vw 0vw 5.64vw 8.21vw'
  }
}));

const Logo = styled('img')(({ theme }) => ({
  display: 'flex',
  height: '2.08vw',
  margin: '2.08vw 0vw 2.08vw 6.39vw',
  content: `url(${logo})`,
  [theme.breakpoints.down('mobile')]: {
    width: '11.54vw',
    height: 'auto',
    margin: '0',
    content: `url(${logoSolo})`
  }
}));

const Header = () => {
  const { pathname } = useLocation();

  if (pathname.toLowerCase().includes(routes.dashBoard.toLowerCase())) return null;

  return (
    <HeaderContainer>
      <HeaderBar position="static" color="inherit">
        <ToolBar>
          <Logo alt="Mather" />
        </ToolBar>
      </HeaderBar>
    </HeaderContainer>
  );
};

export default Header;
