import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#19A664', light: 'rgba(25, 166, 100, 0.1)' },
    secondary: { main: '#F5F3F1', dark: '#b5b3b1' },
    info: { main: '#27534E', light: 'rgba(39, 83, 78, 0.7)' },
    black012: { main: 'rgba(0, 0, 0, 0.12)' },
    black026: { main: 'rgba(0, 0, 0, 0.26)' },
    black038: { main: 'rgba(0, 0, 0, 0.38)' },
    black087: { main: 'rgba(0, 0, 0, 0.87)' },
    gray1: { main: 'rgba(245, 243, 241, 1)' },
    white: { main: '#FFFFFF' },
    black: { main: '#000000' },
    cream: { main: '#F4EFED' },
    quickbooks: { main: '#2CA01C', dark: '#217515' },
    rainbow: {
      color1: { main: '#27534E', light: 'rgba(39,83,78,0.85)', lighter: 'rgba(39,83,78,0.15)' },
      color2: { main: '#19A664', light: 'rgba(25,166,100,0.85)', lighter: 'rgba(25,166,100,0.15)' },
      color3: {
        main: '#72FFB2',
        light: 'rgba(114,255,178,0.85)',
        lighter: 'rgba(114,255,178,0.15)'
      },
      color4: { main: '#F3D355', light: 'rgba(243,211,85,0.85)', lighter: 'rgba(243,211,85,0.15)' },
      color5: { main: '#F99E58', light: 'rgba(249,158,88,0.85)', lighter: 'rgba(249,158,88,0.15)' },
      color6: { main: '#D04947', light: 'rgba(208,73,71,0.85)', lighter: 'rgba(208,73,71,0.15)' },
      color7: { main: '#2D9CDB', light: 'rgba(45,156,219,0.85)', lighter: 'rgba(45,156,219,0.15)' },
      color8: { main: '#2C6FD5', light: 'rgba(44,111,213,0.85)', lighter: 'rgba(44,111,213,0.15)' },
      color9: {
        main: '#9A87F5',
        light: 'rgba(154,135,245,0.85)',
        lighter: 'rgba(154,135,245,0.15)'
      },
      color10: { main: '#5C4CA8', light: 'rgba(92,76,168,0.85)', lighter: 'rgba(92,76,168,0.15)' },
      color11: { main: '#2C0559', light: 'rgba(44,5,89,0.85)', lighter: 'rgba(44,5,89,0.15)' },
      color12: { main: '#142A27', light: 'rgba(20,42,39,0.85)', lighter: 'rgba(20,42,39,0.15)' }
    }
  },
  typography: {
    fontFamily: 'DM Sans',
    fontSize: '1.6rem',
    h1: {
      fontFamily: 'DM Serif Display',
      fontSize: '3.6rem'
    },
    h2: {
      fontSize: '1.8rem'
    },
    h3: {
      fontSize: '1.4rem'
    },
    h4: {
      fontSize: '2.4rem'
    }
  },
  breakpoints: {
    values: {
      mobile: 481,
      tablet: 769,
      laptop: 1025,
      desktop: 1201
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          textTransform: 'capitalize',
          fontSize: '1.6rem',
          paddingBottom: '2rem',
          paddingTop: '2rem'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF'
        }
      }
    }
  }
});

export default theme;
