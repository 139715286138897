import httpClient from 'httpClient';
import { BASE_AUTH_URL, BASE_CONFIRMATIONS_URL } from 'constants/networkConstants';

class UserService {
  static login(user) {
    return httpClient.post(`${BASE_AUTH_URL}/signin`, user);
  }

  static logout() {
    return httpClient.post(`${BASE_AUTH_URL}/logout`);
  }

  static signUp(user) {
    return httpClient.post(`${BASE_AUTH_URL}/signup`, user);
  }

  static resendConfirmationEmail(email) {
    return httpClient.get(`${BASE_CONFIRMATIONS_URL}/resend/${email}`);
  }

  static recoverPassword(email) {
    return httpClient.post(`${BASE_AUTH_URL}/recover`, email);
  }

  static resetPassword(password, token) {
    return httpClient.post(`${BASE_AUTH_URL}/reset/${token}`, { password });
  }
}
export default UserService;
