import React from 'react';

import routesPaths from 'constants/routesPaths';
import NotFoundPage from 'pages/NotFoundPage';
import LandingPage from 'pages/LandingPage';

const routes = [
  {
    path: [routesPaths.index, routesPaths.aboutUs],
    component: <LandingPage />,
    exact: true
  },
  {
    component: <NotFoundPage />
  }
];

export default routes;
