import dashboardService from 'services/dashboardService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const getCompanyDashboard = createAsyncThunk(
  'dashboard/getCompanyDashboard',
  async ({ companyId, dashboardParams, indicatorName }) => {
    try {
      const { data } = await dashboardService.getCompanyDashboard(companyId, dashboardParams);
      return {
        indicatorId: dashboardParams.indicatorId,
        indicatorName,
        data,
        groupBy: dashboardParams.groupBy,
        timePeriodType: dashboardParams.timePeriodType
      };
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const getDealDashboard = createAsyncThunk(
  'dashboard/getDealDashboard',
  async ({ dealId, dashboardParams, indicatorName }) => {
    try {
      const { data } = await dashboardService.getDealDashboard(dealId, dashboardParams);
      return {
        indicatorId: dashboardParams.indicatorId,
        data,
        indicatorName,
        groupBy: dashboardParams.groupBy,
        timePeriodType: dashboardParams.timePeriodType
      };
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const {
  fulfilled: getCompanyDashboardFulfilled,
  rejected: getCompanyDashboardRejected
} = getCompanyDashboard;
export const {
  fulfilled: getDealDashboardFulfilled,
  rejected: getDealDashboardRejected
} = getDealDashboard;
