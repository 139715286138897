import React from 'react';
import { styled, Box, Typography } from '@mui/material';

import firstImage from '../../assets/images/firstPartLanding.jpg';

const FirstPart = styled(Box)(({ theme }) => ({
  height: '40.21vw',
  backgroundColor: theme.palette.info.main,
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%'
  }
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginLeft: '3.47vw',
  [theme.breakpoints.down('mobile')]: {
    marginLeft: '4.1vw',
    paddingBottom: '13.08vw'
  }
}));

const TitleFirstPart = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.h1.fontFamily,
  fontSize: '4.8rem',
  lineHeight: '4.8rem',
  paddingTop: '4.93vw',
  color: theme.palette.white.main,
  width: '31.94vw',
  [theme.breakpoints.down('mobile')]: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.fontSize,
    paddingTop: '10.26vw',
    paddingRight: '38.46vw',
    width: '100%',
    height: 'auto'
  }
}));

const FirstImage = styled('img')(({ theme }) => ({
  width: '49.31vw',
  display: 'flex',
  alignContent: 'flex-end',
  objectFit: 'cover',
  [theme.breakpoints.down('mobile')]: {
    display: 'none'
  }
}));

const LandingPageFirstPart = () => {
  return (
    <FirstPart>
      <TitleContainer>
        <TitleFirstPart>
          Personalizing and simplifying private market transactions for small and medium-sized
          businesses.
        </TitleFirstPart>
      </TitleContainer>
      <FirstImage src={firstImage} />
    </FirstPart>
  );
};

export default LandingPageFirstPart;
