import React from 'react';
import { styled, Box, Typography } from '@mui/material';

import fifthImage from '../../assets/images/fifthPartLanding.jpg';

const FifthPart = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '5.9vw 7.71vw',
  backgroundColor: theme.palette.info.light,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.white.main,
  '&::after': {
    opacity: '0.9',
    content: '""',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${fifthImage})`,
    position: 'absolute',
    zIndex: 1,
    mixBlendMode: 'multiply',
    backgroundPositionX: 'center',
    backgroundPositionY: 'center'
  },
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    padding: '20.77vw 0'
  }
}));

const FifthPartText = styled(Typography)(({ theme }) => ({
  fontSize: '4.8rem',
  lineHeight: '100%',
  zIndex: 2,
  [theme.breakpoints.down('mobile')]: {
    fontSize: '2.4rem'
  }
}));

const LandingPageFifthPart = () => {
  return (
    <FifthPart>
      <FifthPartText variant="h1">Coming soon</FifthPartText>
    </FifthPart>
  );
};

export default LandingPageFifthPart;
