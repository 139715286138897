const routes = {
  index: '/',
  dashBoard: '/dashboard',
  dashBoard_deals: '/dashboard-deals',
  dashBoard_financial: '/dashboard-financial',
  login: '/login',
  recoverPassword: '/recover-password',
  resetPassword: '/reset-password',
  registerUser: '/register-user',
  aboutUs: '/about-us'
};

export default routes;
