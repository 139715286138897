import httpClient from 'httpClient';
import { BASE_INDICATORS_URL } from 'constants/networkConstants';

class IndicatorsService {
  static getIndicators() {
    return httpClient.get(BASE_INDICATORS_URL);
  }

  static getIndicator(indicatorId) {
    return httpClient.get(`${BASE_INDICATORS_URL}/${indicatorId}`);
  }
}

export default IndicatorsService;
