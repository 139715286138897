/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const useMediaQuery = query => {
  const mediaQuery = window.matchMedia(query);
  const [match, setMatch] = useState(!!mediaQuery.matches);

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener(handler);
  }, []);

  return match;
};

export default useMediaQuery;
