import { createSlice } from '@reduxjs/toolkit';
import { getMetricsFulfilled } from 'state/actions/metricsActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  metrics: []
};

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  extraReducers: {
    [getMetricsFulfilled]: (state, { payload }) => {
      state.metrics = payload;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default metricsSlice.reducer;
