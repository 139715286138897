import httpClient from 'httpClient';
import { BASE_QUICKBOOKS_URL } from 'constants/networkConstants';

class QuickbooksService {
  static auth() {
    return httpClient.get(`${BASE_QUICKBOOKS_URL}/oauth/authUri`);
  }

  static profitAndLossReport(realmId, accessToken, year) {
    if (realmId && accessToken) {
      return httpClient.get(
        `${BASE_QUICKBOOKS_URL}/profitAndLossReport?realmId=${realmId}&accessToken=${accessToken}&year=${year}`
      );
    }
  }
}
export default QuickbooksService;
