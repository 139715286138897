import { createSlice } from '@reduxjs/toolkit';
import {
  getCompanyDashboardFulfilled,
  getDealDashboardFulfilled
} from 'state/actions/dashboardActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  companyDashboard: [],
  dealDashboard: []
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  reducers: {
    cleanDeals: state => {
      state.dealDashboard = [];
    }
  },
  initialState,
  extraReducers: {
    [getCompanyDashboardFulfilled]: (state, { payload }) => {
      if (payload.data.length) {
        state.companyDashboard = [...state.companyDashboard, payload];
      }
    },
    [getDealDashboardFulfilled]: (state, { payload }) => {
      if (payload.data.length) {
        state.dealDashboard = [...state.dealDashboard, payload];
      }
    },
    [logoutFulfilled]: () => initialState
  }
});

export const { actions } = dashboardSlice;
export default dashboardSlice.reducer;
