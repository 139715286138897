export default {
  'login.title': 'Iniciar Sesion en Mather',
  'login.signup': 'Registrarse',
  'login.requireacc': 'No tienes cuenta?',

  'login.form.email': 'Email',
  'login.form.password': 'Contraseña',
  'login.form.submit': 'Iniciar sesion',

  'login.forgot_password': '¿Olvidó su contraseña?',
  'login.form.email.presence': 'Debe ingresar un email para continuar',
  'login.form.email.invalid': 'Debe ingresar un email válido',
  'login.form.password.presence': 'Debe ingresar una contraseña para continuar',

  'signup.title': 'REGISTRARSE',
  'signup.signin': 'Iniciar Sesión',
  'signup.form.passconfirmation': 'Confirmación de Contraseña',
  'signup.form.confirmation.presence': 'Debe confirmar su contraseña para continuar',
  'signup.form.confirmation.equality':
    'Su confirmación de contraseña debe ser igual a la contraseña',
  'signup.form.firstName': 'Nombre',
  'signup.form.lastName': 'Apellido',

  'logout.button': 'CERRAR SESION',

  'home.welcome': 'Bienvenido a React Redux Base {user}',

  // errors
  'email.presence': 'Debe ingresar un email para continuar',
  'email.invalid': 'Debe ingresar un email válido',
  'password.presence': 'Debe ingresar una contraseña para continuar',
  'password.invalid': 'El email debe contener al menos 8 caracteres, 1 mayuscula y 1 numero',
  'passwordConfirmation.presence': 'Debe confirmar su contraseña para continuar',
  'passwordConfirmation.equality': 'Su confirmación de contraseña debe ser igual a la contraseña'
};
