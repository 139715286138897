import { combineReducers } from 'redux';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import statusReducer from './statusReducer';
import session from './sessionReducer';
import companies from './companiesReducer';
import metrics from './metricsReducer';
import deals from './dealsReducer';
import indicators from './indicatorsReducer';
import dashboard from './dashboardReducer';
import quickbooks from './quickbooksReducer';

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authenticated', 'info', 'user', 'hasCompanies', 'quickbooksYear', 'companyId'],
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, session),
  companies,
  metrics,
  deals,
  indicators,
  dashboard,
  quickbooks,
  statusReducer
});

export default rootReducer;
