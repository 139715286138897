import { updateSession, logout } from 'state/actions/userActions';

const ACCESS_TOKEN_REQUEST = 'authorization';
const ACCESS_TOKEN_RESPONSE = 'token';

const UNAUTHORIZED = 401;

export default (store, client) => {
  client.interceptors.request.use(config => {
    const { info } = store.getState().session;
    const { headers } = config;
    if (info) {
      const { token } = info;
      config.headers = {
        ...headers,
        [ACCESS_TOKEN_REQUEST]: token
      };
    }
    return config;
  });

  client.interceptors.response.use(
    async response => {
      const { data } = response;
      const token = data[ACCESS_TOKEN_RESPONSE];
      if (token) {
        const session = {
          token
        };
        store.dispatch(updateSession(session));
      }
      return response;
    },
    error => {
      if (error.response && error.response.status === UNAUTHORIZED) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );
};
