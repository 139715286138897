import React from 'react';
import {
  styled,
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  tableCellClasses,
  Paper
} from '@mui/material';
import useMediaQuery from 'hooks/useMediaQuery';
import logo from '../../assets/logoMather.svg';
import logoSolo from '../../assets/logoSolo.svg';

const FourthPart = styled(Box)(({ theme }) => ({
  height: '84.38vw',
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  alignItems: 'flex-start',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    padding: '9.74vw 4.1vw 22.05vw 4.1vw'
  }
}));

const FourthPartPaper = styled(TableContainer)(({ theme }) => ({
  width: '93.06vw',
  height: '66.74vw',
  marginTop: '7.15vw',
  marginLeft: '3.47vw',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    margin: 0
  }
}));

const FourthPartTable = styled(Table)(({ theme }) => ({
  width: '88.19vw',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%'
  }
}));

const Logo = styled('img')(({ theme }) => ({
  maxHeight: '2.27vw',
  content: `url(${logo})`,
  [theme.breakpoints.down('mobile')]: {
    maxHeight: '7.95vw',
    textAlign: 'center',
    content: `url(${logoSolo})`
  }
}));

const LeftCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: theme.typography.h4.fontSize,
  width: '26.67vw',
  fontWeight: 700,
  paddingRight: '1.88vw',
  [`&.${tableCellClasses.head}`]: {
    paddingTop: '3.96vw',
    paddingBottom: '2.36vw'
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.4rem',
    lineHeight: '120%',
    paddingRight: '3.33vw',
    [`&.${tableCellClasses.head}`]: {
      paddingTop: '8.46vw',
      paddingBottom: '3.08vw'
    }
  }
}));

const CenterCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.info.main,
  fontSize: theme.typography.h4.fontSize,
  width: '32.22vw',
  paddingLeft: '2.08vw',
  [`&.${tableCellClasses.head}`]: {
    paddingTop: '3.96vw',
    paddingBottom: '2.36vw'
  },
  [`&.${tableCellClasses.body}`]: {
    paddingTop: '1.25vw',
    paddingBottom: '1.25vw'
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.4rem',
    lineHeight: '120%',
    paddingLeft: '3.85vw',
    paddingRight: '2.56vw',
    [`&.${tableCellClasses.head}`]: {
      paddingTop: '8.46vw',
      paddingBottom: '3.08vw',
      textAlign: 'center'
    },
    [`&.${tableCellClasses.body}`]: {
      paddingTop: '3.85vw',
      paddingBottom: '3.33vw'
    }
  }
}));

const RightCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: theme.typography.h4.fontSize,
  width: '32.08vw',
  paddingLeft: '1.53vw',
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
    paddingTop: '3.96vw',
    paddingBottom: '2.36vw'
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.4rem',
    lineHeight: '120%',
    paddingLeft: '2.31vw',
    paddingRight: '2.56vw',
    [`&.${tableCellClasses.head}`]: {
      fontWeight: 700,
      paddingTop: '8.46vw',
      paddingBottom: '3.08vw',
      letterSpacing: '0.005em'
    }
  }
}));

const StyledTableRow = styled(TableRow)({
  verticalAlign: 'baseline',
  '&:last-child td, &:last-child th': {
    border: 0
  }
});

const rows = [
  { capabilities: 'Implementation timeline', mather: '1-2 days', alternatives: '1 to 6 weeks' },
  {
    capabilities: 'Earn-Out Focus',
    mather: 'Mather focuses on earn-out visualizations, tracking, and benchmarking',
    alternatives: 'Focus on data storage'
  },
  {
    capabilities: 'Data Organization Layer',
    mather: 'Mather organizes messy data sets in an automated way',
    alternatives: 'Store excel and other types of files'
  },
  {
    capabilities: 'Security',
    mather: 'Information in encrypted and stores in the cloud (AWS)',
    alternatives: 'Documents an be easily lost or stolen'
  },
  {
    capabilities: 'Cost',
    mather:
      'Transparent pricing, starting at just $49/seat per month with a one-time, $5.000 setup fee',
    alternatives: 'Complex, opaque pricing'
  },
  {
    capabilities: 'Integrations',
    mather:
      'Mather is connected to major financial software systems: including Quickbooks, Xero, and others',
    alternatives: 'Few integrations'
  },
  {
    capabilities: 'Support',
    mather:
      'Mather is headquarteres in the United States and all support staff are based in the U.S',
    alternatives: 'Email and foreign support'
  }
];

const LandingPageFourthPart = () => {
  const isMobile = useMediaQuery('(max-width: 480px)');

  return (
    <FourthPart>
      <FourthPartPaper component={Paper}>
        <FourthPartTable>
          <TableHead>
            <TableRow>
              <LeftCell align="right">{'Platform Capabilities'.toUpperCase()}</LeftCell>
              <CenterCell>
                <Logo alt="Mather" />
              </CenterCell>
              <RightCell>{'Legacy Alternatives'.toUpperCase()}</RightCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow key={row.capabilities}>
                <LeftCell align="right">
                  {isMobile && index === 0 ? 'Implementa-tion timeline' : row.capabilities}
                </LeftCell>
                <CenterCell>{row.mather}</CenterCell>
                <RightCell>{row.alternatives}</RightCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </FourthPartTable>
      </FourthPartPaper>
    </FourthPart>
  );
};

export default LandingPageFourthPart;
