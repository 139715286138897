import { toast } from 'react-toastify';

export const dispatchErrorToast = errorText => {
  toast.error(errorText, {
    position: 'top-right',
    autoClose: 5000,
    bodyClassName: 'grow-font-size',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined
  });
};
