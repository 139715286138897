import quickbooksService from 'services/quickbooksService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const auth = createAsyncThunk('quickbooks/auth', async () => {
  try {
    const { data } = await quickbooksService.auth();
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const profitAndLossReport = createAsyncThunk(
  'quickbooks/profitAndLossReport',
  async ({ realmId, accessToken, year }) => {
    try {
      const { data } = await quickbooksService.profitAndLossReport(realmId, accessToken, year);
      return data;
    } catch ({ response: { data } }) {
      dispatchErrorToast(data.description);
    }
  }
);

export const { fulfilled: authFulfilled, rejected: authRejected } = auth;
export const { fulfilled: reportFulfilled, rejected: reportRejected } = profitAndLossReport;
