export default {
  'login.title': 'Log In to Mather',
  'login.requireacc': "Don't have an account? ",
  'login.signup': 'Sign Up',
  'login.form.email': 'Email',
  'login.form.password': 'Password',
  'login.form.submit': 'Log In',

  'login.forgot_password': 'Forgot your password?',

  'signup.title': 'Sign Up to Mather',
  'signup.member': 'Already a member? ',
  'signup.login': 'Log In',
  'signup.form.submit': 'Agree & Join',
  'signup.form.passconfirmation': 'Password confirmation',
  'signup.form.firstName': 'First Name',
  'signup.form.lastName': 'Last Name',

  'logout.button': 'LOGOUT',

  'recover.title': "Can't log in?",
  'recover.login': 'Return to log in',
  'recover.form.title': "We'll send a recovery link to",
  'recover.form.email': 'Email',
  'recover.form.submit': 'Send recovery link',
  'recover.error': 'There was an error sending the recovery link',

  'reset.title': 'Reset your password',
  'reset.form.password': 'Password',
  'reset.form.confirmPassword': 'Confirm password',
  'reset.form.submit': 'Reset password',
  'reset.error': 'There was an error resetting your password',

  'home.welcome': 'Welcome to React Redux Base {user}',

  // errors
  'firstName.presence': 'You must enter a first name to continue',
  'lastName.presence': 'You must enter a last name to continue',
  'field.length': 'Must be between 2 and 20 characters',
  'field.characters': 'Only letters and spaces allowed',
  'email.presence': 'You must enter an email to continue',
  'email.invalid': 'You must enter a valid email',
  'password.presence': 'You must enter a password to continue',
  'password.invalid':
    'Your password must be at least 8 characters long, 1 capital letter and 1 number',
  'passwordConfirmation.presence': 'You must enter a password confirmation to continue',
  'passwordConfirmation.equality': 'Your password confirmation must be equal to the password',
  'confirmPassword.equality': 'Your password confirmation must be equal to the password'
};
