import indicatorsService from 'services/indicatorsService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const getIndicators = createAsyncThunk('indicators/getIndicators', async () => {
  try {
    const { data } = await indicatorsService.getIndicators();
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const getIndicator = createAsyncThunk('indicators/getIndicator', async indicatorId => {
  try {
    const { data } = await indicatorsService.getIndicator(indicatorId);
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const { fulfilled: getIndicatorsFulfilled, rejected: getIndicatorsRejected } = getIndicators;
export const { fulfilled: getIndicatorFulfilled, rejected: getIndicatorRejected } = getIndicator;
