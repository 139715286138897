import React from 'react';
import { styled, Box, Typography } from '@mui/material';

const ThirdPart = styled(Box)(({ theme }) => ({
  height: '50.42vw',
  backgroundColor: theme.palette.info.main,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    padding: '9.74vw 4.36vw 0vw 4.36vw'
  }
}));

const ThirdPartTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.white.main,
  marginTop: '7.43vw',
  width: '44.44vw',
  lineHeight: '100%',
  textAlign: 'center',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    margin: 0,
    fontSize: '2.4rem',
    padding: '0vw 6.15vw'
  }
}));

const ThirdPartBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '0.83vw',
  flexWrap: 'wrap',
  justifyContent: 'center',
  textAlign: 'center',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    width: '100%',
    marginTop: '5.64vw',
    marginBottom: '19.49vw',
    fontSize: '2.4rem'
  }
}));

const ThirdPartBox = styled(Box)(({ theme }) => ({
  borderRadius: '6.94vw',
  borderStyle: 'solid',
  borderWidth: '0.14vw',
  borderColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  padding: '2.08vw 3.47vw',
  marginRight: '2.22vw',
  marginTop: '3.13vw',
  [theme.breakpoints.down('mobile')]: {
    height: 'auto',
    marginTop: '8.21vw',
    marginRight: 0,
    padding: '3.08vw 10.77vw',
    borderRadius: '25.64vw'
  }
}));

const TextBox = styled(Typography)(({ theme }) => ({
  color: '#ffffff',
  fontWeight: '500',
  [theme.breakpoints.down('mobile')]: {
    fontSize: '1.6rem'
  }
}));

const LandingPageThirdPart = () => {
  return (
    <ThirdPart>
      <ThirdPartTitle variant="h1">
        Mather’s initial product, Mather Basic, provides the following
      </ThirdPartTitle>
      <ThirdPartBoxContainer>
        <ThirdPartBox>
          <TextBox variant="h4">Secure Data Storage in the Cloud</TextBox>
        </ThirdPartBox>
        <ThirdPartBox>
          <TextBox variant="h4">Data Formatting Services</TextBox>
        </ThirdPartBox>
        <ThirdPartBox>
          <TextBox variant="h4">Key Metric (EBITDA, Gross Revenue, etc.) Calculations</TextBox>
        </ThirdPartBox>
        <ThirdPartBox>
          <TextBox variant="h4">Data Benchmarking</TextBox>
        </ThirdPartBox>
        <ThirdPartBox>
          <TextBox variant="h4">Earn-Out Management and Visualization Layer</TextBox>
        </ThirdPartBox>
      </ThirdPartBoxContainer>
    </ThirdPart>
  );
};

export default LandingPageThirdPart;
