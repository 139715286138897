import httpClient from 'httpClient';
import { BASE_COMPANIES_URL, BASE_USER_URL, BASE_INDUSTRIES_URL } from 'constants/networkConstants';

class CompanyService {
  static getCompanies() {
    return httpClient.get(BASE_COMPANIES_URL);
  }

  static createCompany(company) {
    return httpClient.post(BASE_COMPANIES_URL, company);
  }

  static getCompany(companyId) {
    return httpClient.get(`${BASE_COMPANIES_URL}/${companyId}`);
  }

  static deleteCompany(companyId) {
    return httpClient.delete(`${BASE_COMPANIES_URL}/${companyId}`);
  }

  static postProfitAndLoss(id, accounts) {
    return httpClient.post(`${BASE_COMPANIES_URL}/${id}/profit-and-loss`, { accounts });
  }

  static getIndustries() {
    return httpClient.get(BASE_INDUSTRIES_URL);
  }

  static getIndustry(industryId) {
    return httpClient.get(`${BASE_INDUSTRIES_URL}/${industryId}`);
  }

  static getCompanyAccounts(companyId) {
    return httpClient.get(`${BASE_COMPANIES_URL}/${companyId}/accounts`);
  }

  static getCompanyUsers(companyId) {
    return httpClient.get(`${BASE_COMPANIES_URL}/${companyId}/users`);
  }

  static getUser(id) {
    return httpClient.get(`${BASE_USER_URL}/${id}`);
  }

  static getUserCompanies() {
    return httpClient.get(`${BASE_USER_URL}/companies`);
  }
}

export default CompanyService;
