// App common constants

export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';
export const MONTH_NAMES = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
  'ene',
  'abr',
  'ago',
  'dic'
];
export const QUARTER_NAMES = ['quarter', 'q', 'q1', 'q2', 'q3', 'q4', 'qtr'];
// TODO:
// const MONTH_NAMES = {
//   capitalized: ["January", "Febrary"],
//   reduced: ["Jan", "Feb"],
//   spanish: ["Enero", "Febrero"].
// }
export const COMPANY_SIZES = ['0-1', '2-10', '11-50', '51-200', '201-500', '501-1,000', '1,000+'];
export const INDICATOR_NAMES = {
  grossRevenue: 'Gross Revenue',
  operatingExpenses: 'Operating Expenses',
  cogs: 'COGS',
  itda: 'ITDA',
  netIncome: 'Net Income',
  netRevenue: 'Net Revenue',
  ebitda: 'EBITDA'
};
