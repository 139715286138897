import metricsService from 'services/metricsService';
import createAsyncThunk from 'utils/createAsyncThunk';
import { dispatchErrorToast } from 'utils/uiUtils';

export const getMetrics = createAsyncThunk('metrics', async () => {
  try {
    const { data } = await metricsService.getMetrics();
    return data;
  } catch ({ response: { data } }) {
    dispatchErrorToast(data.description);
  }
});

export const { fulfilled: getMetricsFulfilled, rejected: getMetricsRejected } = getMetrics;
