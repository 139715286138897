import { useSelector, shallowEqual } from 'react-redux';

const useSession = () =>
  useSelector(
    ({ session: { authenticated, user, hasCompanies, quickbooksYear, companyId } }) => ({
      authenticated,
      user,
      hasCompanies,
      quickbooksYear,
      companyId
    }),
    shallowEqual
  );

export default useSession;
