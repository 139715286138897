import { createSlice } from '@reduxjs/toolkit';
import {
  getDealFulfilled,
  getCompanySharedDealsFulfilled,
  getCompanyRecievedDealsFulfilled,
  cancelDealFulfilled,
  createDealFulfilled
} from 'state/actions/dealsActions';
import { logoutFulfilled } from 'state/actions/userActions';

const initialState = {
  deal: {},
  companySharedDeals: [],
  updateSharedDeals: true,
  companyReceivedDeals: [],
  updateRecievedDeals: true
};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  extraReducers: {
    [getDealFulfilled]: (state, { payload }) => {
      state.deal = payload;
    },
    [getCompanySharedDealsFulfilled]: (state, { payload }) => {
      state.companySharedDeals = payload;
      state.updateSharedDeals = false;
    },
    [getCompanyRecievedDealsFulfilled]: (state, { payload }) => {
      state.companyReceivedDeals = payload;
      state.updateRecievedDeals = false;
    },
    [cancelDealFulfilled]: (state, { payload }) => {
      state.deal = payload;
      state.updateSharedDeals = true;
      state.updateRecievedDeals = true;
    },
    [createDealFulfilled]: (state, { payload }) => {
      state.deal = payload;
      state.updateSharedDeals = true;
    },
    [logoutFulfilled]: () => initialState
  }
});

export default dealsSlice.reducer;
