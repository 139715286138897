import { createSlice } from '@reduxjs/toolkit';
import { authFulfilled, reportFulfilled } from 'state/actions/quickbooksActions';

const initialState = {
  authUrl: '',
  profitAndLossReport: {}
};

const quickbooksSlice = createSlice({
  name: 'quickbooks',
  initialState,
  extraReducers: {
    [authFulfilled]: (state, { payload }) => {
      state.authUrl = payload;
    },
    [reportFulfilled]: (state, { payload }) => {
      state.profitAndLossReport = payload;
    }
  }
});

export default quickbooksSlice.reducer;
