import httpClient from 'httpClient';
import { BASE_DASHBOARD_URL } from 'constants/networkConstants';

class DashboardService {
  static getCompanyDashboard(companyId, dashboardParams) {
    return httpClient.post(`${BASE_DASHBOARD_URL}/company/${companyId}`, dashboardParams);
  }

  static getDealDashboard(dealId, dashboardParams) {
    return httpClient.post(`${BASE_DASHBOARD_URL}/deal/${dealId}`, dashboardParams);
  }
}

export default DashboardService;
